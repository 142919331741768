.manager {
  background-color: var(--panelBg);
  padding: 15px;
  color: var(--textTheme);
  margin: 10px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
}

.manager span {
  font-size: 18px;
}
