:root {
  --gold: #d79839;
}

.reusable-input {
  display: inline-flex;
  padding: 4px 4px 8px 4px;
  border-bottom: 1px solid rgb(165, 161, 161);
  margin: 10px 0;
  transition-duration: 0.3s;
  width: 20rem;
  min-height: 30px;
  max-width: 100%;
}

.reusable-input input {
  border: none;
  padding-left: 10px;
  font-size: 15px;
  height: 20px;
  background: var(--backgroundTheme);
  color: var(--textTheme);
  width: 100%;
}

.golden-button {
  background-color: var(--gold);
  color: #ffffff;
  border: none;
  border-radius: 200px;
  font-size: 19px;
  padding: 0.7rem 2rem;
  cursor: pointer;
}

.invalid-input {
  border-color: rgb(255, 35, 35);
}

.invalid-input input {
  color: rgb(255, 35, 35);
}

.invalid-input.input-focused {
  border-color: rgb(255, 35, 35) !important;
}

.input-focused {
  border-color: gold;
}

.input-focused input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1; /* Firefox */
}

.link {
  color: gold;
  text-decoration: underline;
}

.user-primary-button {
  border: none;
  background-color: #6496ed;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.user-load-button {
  color: rgba(0, 0, 0, 0);
  background-image: url("../assets/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30%;
}

.user-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  transition-duration: 0.3s;
}

.user-modal {
  padding: 30px;
  background-color: var(--backgroundTheme);
  border-radius: 16px;
  color: var(--colorTheme) !important;
  position: relative;
  max-width: 40vw;
  max-height: 40vh;
  min-width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  animation: spawn 0.3s;
}

.user-modal img{
  max-height: 100%;
  max-width: 100%;
}

.user-modal-x {
  position: absolute;
  top: 10px;
  right: 10px;
  filter: invert(1);
  cursor: pointer;
}

.toggle-container{
  height: 20px;
  width: 40px;
  border-radius: 20px;
  background:gray;
  transition-duration: .3s;
  cursor: pointer;
}

.toggle-container div{
  height: 18px;
  width: 18px;
  margin: 1px;
  display: inline-flex;
  border-radius: 20px;
  background: var(--textTheme);
  transition-duration: .3s;
}

.toggled{
  background-color: red;
}

.toggled div{
  transform: translateX(20px);
}

@keyframes spawn {
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.01);
  }
  75% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}

.lari img {
  width: 18px;
  transform: translateY(2px);
}

.lari .lari-white {
  filter: invert(1);
}

.black {
  background-color: #000000 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d79839;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8762c;
}

@media (max-width: 768px) {
  .user-modal {
    max-width: 90vw;
    max-height: 90vh;
  }
}