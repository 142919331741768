.stats-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #fed700;
  padding: 6rem 0;
}

.stat {
  color: #ffffff;
}

.stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .stats-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .stat{
    margin: 20px 0;
  }
}

@media (max-width: 568px) {
  .stats-container {
    grid-template-columns: auto;
  }
}
