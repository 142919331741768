.responsive-grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1024px) {
  .responsive-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .responsive-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 568px) {
  .responsive-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
