.widget-item{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 100vw;
    box-sizing: border-box;
    align-items: center;
}

.widget-item-img-container{ 
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
}

.widget-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.widget-item span{
    color: #fff;
    font-size: 1.5em;
}

.items-container-widget{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}