.pricing-plans-section{
    background-color: #fed700;
    padding: 3% 3%;
}

.pricing-plans{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.pricing-plan{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin: 0 13%;
    border-radius: 25px;
    padding: 7% 4%;
    text-align: center;
}

.pricing-plans-header{
    text-align: center;
    margin-bottom: 4%;
}

.pricing-plans-header h1{
    margin: 0;
    font-size: 1.9em;
}

.plan-title{
    font-weight: bold;
    font-size: 1.2em;
}

.plan-sess{
    font-weight: bold;
}

.plan-header > *{
    margin: .7em 0;
}

.plan-logo{
    width: 15%;
    margin-bottom: 5%;
}

.plan-logo img{
    width:100%;
    height: 100%;
    object-fit: cover;
}

.plan-bottom-part button{
    background-color: tomato;
    border: none;
    padding: 15px 30px;
    border-radius: 25px;
    font-size: 1em;
    color: white;
    cursor: pointer;
}

.plan-bottom-part p{
    font-size: 1.9em;
    color: tomato;
    margin-top: 0;
    margin-bottom: .7em;
}

.plan-desc{
    margin: 17% 0;
    padding: 0 3px;
}

@media only screen and (max-width: 768px){
    .pricing-plans{
        grid-template-columns: initial;
        grid-template-rows: repeat(3,1fr);
    }
    .pricing-plan{
        margin: 6% 10%;
    }
    .pricing-plans-section {
        padding: 7% 3%;
    }
}