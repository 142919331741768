.stylists-grid {
  padding: 20px;
  column-gap: 10px;
  display: grid;
  min-height: 10em;
  grid-template-columns: repeat(4, 1fr);
  background-color: var(--backgroundTheme);
}

.user-bookings-header {
  display: flex;
  flex-direction: column;
  transition-duration: .3s;
}

.stylist-flex{
  display: flex;
  justify-content: space-around;
}

.red{
  color: red;
}

.green{
  color: green;
}

.user-stylist-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: var(--textTheme);
  background: rgba(0, 0, 0, 0.315);
}

.user-stylist-name {
  font-size: 23px;
}

.user-stylist-info {
  padding: 10px 0;
  text-align: center;
}

.user-stylist-info .border {
  height: 1px !important;
  margin: 0 !important;
  transition-duration: 0.3s;
}

.golden {
  background: rgb(255, 217, 0) !important;
}

.user-stylist-info p {
  margin: 5px 0;
}

.user-stylist-image-container {
  height: 300px;
  overflow: hidden;
  position: relative;
}

.user-stylist-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 0.3s;
}

.user-stylist-image-container:hover img {
  transform: scale(1.1);
}

.user-stylist-container:hover .border {
  width: 150px !important;
}

.stylists-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/stylists.jpg") !important;
  background-position: center 30% !important;
}
.makeup-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/makeup.jpg") !important;
  background-position: center 60% !important;
}
.cosmetolog-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/cosmetolog.jpg") !important;
  background-position: center 50% !important;
}
.nails-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/nails.jpg") !important;
  background-position: center 60% !important;
}

.centered-bg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.user-switch-container {
  display: flex;
  margin-top: 15px;
}

.user-switch-container div {
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 8px 12px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.user-switch-active {
  background-color: #d79839;
}

@media (max-width: 768px) {
  .prof-switch {
    flex-direction: column;
  }

  .user-bookings-header{
    height: auto !important;
    padding: 20px 0;
  }
}
