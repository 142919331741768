.notfound-icon {
  text-align: center;
  max-width: 100vw;
  color: var(--textTheme);
}

.notfound-button-container {
  text-align: center;
  margin-top: 50px;
}

.notfound-icon img{
  max-width: 100vw;}

.notfound-icon h2 {
  margin: 0;
}

@media(max-width: 768px){
  .notfound-icon{
    margin-top: 20vh;
  }
}