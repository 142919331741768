@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.landing-intro {
  width: 100%;
  min-height: calc(100vh - 100px);
  max-width: 100vw !important;
  overflow: hidden !important;
  /* height: 100vh; this line is useless */
  display: grid;
  grid-template-columns: 50% 50%;
  overflow-x: hidden;
}

.langind-text-container {
  display: block;
  margin: auto 10%;
  /*
  Useless lines
  width: 90%;
  padding-right: 60px;
  */
}

.landing-intro-txt {
  width: 85%;
  height: 100%;
  color: var(--textTheme);
}

.landing-intro .slick-track {
  display: flex;
}
.landing-intro .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.landing-intro-txt-wrapper{
  height: 100%;
  flex-direction: column;
  display: flex;
}
.landing-intro-txt h1 {
  font-size: 4em;
  color: rgb(214, 152, 56);
}

.landing-intro-txt p {
  line-height: 100%;
}

.landing-intro-img-container {
  margin: auto 0;
}

.landing-intro-img {
  height: 70%;
  width: 80%;
  overflow: hidden;
  margin: auto;
  /* Added alignment */
  text-align: center;
}

.landing-intro-img img {
  height: 80%;
  width: 80%;
  border-radius: 500px;
  border: 4px solid white;
  object-fit: contain;
  display: inline-block;
  /* display inline-block important to override slick styles */
  /* Contain istead of cover, made slightly smaller */
}

@media (max-width: 768px) {
  .landing-intro {
    grid-template-columns: 100%;
  }

  .landing-intro-txt-wrapper h1{
    font-size: 32px;
  }
}
