.managers-page-content {
  background-color: var(--panelDim);
  padding: 20px 0;
}

.managers-func{
  text-align: right;
}

@media(max-width: 768px){
  .managers-page-content{
    padding-top: 50px;
  }
}