.cv-radios {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.cv-vip-container {
  position: relative;
  display: flex;
  border: 1px solid rgb(105, 105, 105);
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
}

.cv-vipped-item p {
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.cv-radios p{
  text-align: center;
}

.cv-vipped .cv-vip-absolute-bg {
  transform: translateX(100%);
}

.cv-vip-absolute-bg {
  height: 100%;
  width: 50%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  background-color: var(--gold);
  transition-duration: 0.3s;
}

.cv-vip-item p {
  margin: 0;
  padding: 10px 5px;
  transition-duration: 0.3s;
}

.cv-vip-item {
  width: 150px;
}
