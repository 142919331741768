.user-services-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  padding: 50px 0;
}

.user-services-wrapper {
  margin: 40px 0;
}

.user-service {
  max-width: 250px;
  overflow: hidden;
  min-height: 250px;
  color: #ffffff;
  position: relative;
}

.popular-stylist-header-new{
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  align-items: center;
  padding: 0 2rem;
}

.user-service .service-image {
  height: 250px;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  transition-duration: 0.3s;
}


.user-service-info p {
  margin: 5px 0;
}

.service-fake-bg {
  transition-duration: 0.3s;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
}

.service-fake-bg {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  .user-services-container {
    grid-template-columns: auto auto;
  }

  .user-service {
    margin: 20px 0;
  }
}

@media (max-width: 768px) {
  .user-services-container {
    grid-template-columns: auto;
  }
}
