.footer-top {
  background-color: #28323c;
  display: flex;
  justify-content: space-around;
  padding: 40px 0;
  max-width: 100vw;
  color: #ffffff;
}

.footer-top-part img {
  width: 80px;
}

.footer-top-part a{
  text-decoration: none;
  color: #fff;
}

.footer-top ul {
  list-style-type: none;
}

.footer-top ul li {
  margin: 10px 0;
}

.footer-bottom {
  background-color: #1c252e;
  display: flex;
  justify-content: space-around;
  padding: 30px 0;
  max-width: 100vw;
  color: gray;
}

.footer-bottom a {
  color: gold;
  text-decoration: none;
}

@media (max-width: 768px) {
  .footer-top {
    display: none;
  }
}
