.user-services-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  margin: 50px 30px;
}

.user-service-item {
  color: var(--textTheme);
  display: inline-flex;
  width: 80%;
  justify-content: space-between;
  border-bottom: 1px solid rgb(255, 221, 30);
  transition-duration: .3s;
}

.user-service-item p {
  font-size: 19px;
}

.services-search {
  text-align: center;
}

.services-search input{
  border: none;
  border-bottom: 1px solid gold;
  background: transparent;
  font-size: 19px;
  padding: 8px 12px;
  color: var(--textTheme);
  margin-top: 20px;
}

@media (max-width: 768px) {
  .user-services-grid {
    grid-template-columns: auto;
  }

  .user-service-item {
    width: 90%;
  }
}
