.user-badge {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: var(--gold);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.user-badge span {
  color: #ffffff;
}

.user-menu-bar {
  position: absolute !important;
  left: unset !important;
  right: 10% !important;
  top: 120% !important;
  transform: none !important;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.23) !important;
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.23) !important;
  box-shadow: 0px 0px 8px 0px rgba(88, 63, 63, 0.23) !important;
  border-radius: 4px !important;
  background-color: #ffffff;
}

.user-menu-bar ul {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
}
.user-menu-bar a {
  text-decoration: none;
  font-size: 18px;
  color: #000000;
}

.user-menu-bar ul li {
  padding: 6px 12px;
  display: flex;
  transition-duration: 0.3s;
  align-items: center;
}

.user-menu-bar ul li span {
  margin-left: 10px;
  color: var(--textTheme) !important;
}

.user-menu-bar ul li:hover {
  background-color: var(--gold);
  color: #ffffff !important;
}

.user-menu-bar ul li:hover span {
  color: #ffffff;
}

.bordered {
  border-top: 1px solid rgb(240, 240, 240);
}
