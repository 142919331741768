.employee-item {
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
  width: 100%;
  background-color: var(--panelBg);
  margin: 10px 0;
  color: var(--textTheme);
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 4px;
}

.employee-info {
  display: flex;
  align-items: center;
  font-size: 19px;
}

.employee-info > div {
  margin: 0 10px;
}

.employee-add{
  cursor: pointer;
}

.employee-status {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.employee-status.free {
  color: rgb(0, 185, 0);
}

.employee-status.not-free {
  color: rgb(248, 0, 0);
}

.status-dot {
  height: 6px;
  width: 6px;
  border-radius: 4px;
  margin-right: 5px;
  transform: translateY(1px);
  transition-duration: 0.3s;
}

.status-dot.red {
  background-color: rgb(248, 0, 0);
}

.status-dot.green {
  background-color: rgb(0, 185, 0);
}

.employee-status span {
  user-select: none;
  transition-duration: 0.3s;
}

.arrow {
  width: 20px;
  margin-left: 20px;
  transform: rotate(90deg);
  filter: invert(1);
  transition-duration: 0.3s;
  cursor: pointer;
}

.arrow-y {
  transform: rotate(-90deg);
}

.employee-info-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.employee-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emp-down {
  position: relative;
  text-align: left;
  padding-left: 10px;
}

.del-btn {
  font-size: 13px !important;
  background-color: red !important;
}

.bottom-right {
  display: flex;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.edit-btn {
  background-color: #ffd900 !important;
  margin-left: 5px;
  background-image: url("./icons/edit.svg");
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .employee-item {
    flex-direction: column;
    max-width: 80vw;
    display: inline-flex;
  }

  .employee-info {
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .employee-info > div{
    margin: 5px 0;
  }

  .employee-status{
    display: flex;
    justify-content: center;
  }

  .employee-info-wrapper {
    flex-direction: column;
  }
}
