.navigation-container {
  width: 100%;
  position: relative;
  height: 100vh;
  z-index: 9;
  padding: 10px;
  background-color: var(--panelBg);
}

.navigation-logo {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}

.navigation-logo h2 {
  color: var(--textTheme);
  padding-left: 40px;
  padding-bottom: 10px;
  margin: 0;
  border-bottom: 1px solid white;
  width: 100%;
}

.nav-item {
  display: flex;
  padding: 10px 20px;
  margin: -10px 0;
}

.nav-item:nth-child(1) {
  margin-top: 10px;
}

.nav-item img {
  width: 20px;
  margin-right: 15px;
  filter: invert(59%) sepia(13%) saturate(657%) hue-rotate(185deg)
    brightness(88%) contrast(90%);
}

.nav-active {
  background: var(--panelDim);
  border-radius: 8px;
}

.nav-active img {
  filter: invert(42%) sepia(53%) saturate(1188%) hue-rotate(194deg)
    brightness(88%) contrast(90%);
}

.nav-item-wrapper a {
  text-decoration: none;
  color: #c0c0c0 !important;
}
.nav-active a {
  color: #4471d8 !important;
}

.nav-item {
  transition-duration: 0.3s;
}

.user-nav-bar {
  background-color: var(--backgroundTheme);
}

.nav-item:not(.nav-active):hover {
  filter: invert(42%) sepia(53%) saturate(1188%) hue-rotate(194deg)
    brightness(88%) contrast(90%);
}

.hamburger-button {
  display: none;
}

.gold-active {
  background-color: rgb(255, 217, 0);
  color: #ffffff;
  padding: 10px;
  transform: translateX(-10px);
  border-radius: 10px;
}

.gold-active img {
  filter: invert(99%) sepia(0%) saturate(3085%) hue-rotate(36deg)
    brightness(125%) contrast(98%);
}

.gold-active:hover {
  filter: none !important;
  background-color: rgb(255, 223, 40);
}

.gold-nav {
  cursor: pointer;
}

.nav-wrapper {
  position: relative;
}

.navigation-container {
  position: fixed;
  width: 20%;
}

.gold-nav-switch {
  justify-content: center;
}

.gold-nav-switch > div {
  height: 50px;
  width: 50px;
  text-align: center;
}

.gold-nav-switch > div:not(.gold-chosen):hover {
  background-color: rgba(0, 0, 0, 0.171);
}

@media (max-width: 768px) {
  .hamburger-button {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .nav-opened {
    left: 0 !important;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.295);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.432);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.411);
  }

  .hamburger-button div {
    height: 2px;
    width: 30px;
    margin: 4px 0;
    background-color: #000000;
    transition-duration: 0.3s;
  }

  .hamburger-opened div:nth-child(1) {
    transform: rotate(45deg) translate(4px, 10px);
  }

  .hamburger-opened div:nth-child(2) {
    opacity: 0;
  }

  .hamburger-opened div:nth-child(3) {
    transform: rotate(-45deg) translate(3px, -11px);
  }

  .navigation-container {
    position: fixed;
    left: -200vw;
    top: 0;
    width: 70%;
    height: 100vh;
    transition-duration: 0.3s;
  }
}
