@media (max-width: 768px) {
  .store-page {
    grid-template-columns: auto;
  }
  .records-page-content {
    padding-top: 50px;
  }
  .nav-wrapper {
    position: absolute;
  }
}
