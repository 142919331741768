.lastwork-section {
  margin: 50px 0;
}

.work-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 100vw;
  box-sizing: border-box;
  align-items: center;
}

.work-info {
  display: flex;
  align-items: center;
  color: var(--textTheme);
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.work-item a {
  text-decoration: none;
}

.work-info .stylist-icon-container {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
}

.stylist-icon-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.work-item-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.work-item a {
  -webkit-user-drag: none;
}

.work-item p {
  position: relative;
}

.work-item-img-container {
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
}
