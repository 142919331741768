.employees-page-content {
  background-color: var(--panelDim);
  padding: 20px;
  text-align: center;
}

.add-stylist {
  cursor: pointer;
  margin-top: 0 !important;
}

.primary-button {
  background-color: #4471d8;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 0.4rem 0.8rem;
}
