.message-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-form h3 {
  color: #ffffff;
}

.message-form textarea {
  font-size: 19px;
  padding: 10px;
  min-width: 400px;
  min-height: 10rem;
}

.message-form input{
  min-width: 400px;
  padding: 10px;
  font-size: 19px;
}

.message-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  background-color: var(--panelBg);
  border-radius: 20px;
}

.message-form > *{
    margin: 5px 0;
}