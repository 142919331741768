.my-node-enter {
    opacity: 0;
    max-height: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    max-height: 500px;
    transition: opacity 200ms, max-height 200ms;
  }
  .my-node-exit {
    opacity: 1;
    max-height: 500px;
  }
  .my-node-exit-active {
    opacity: 0;
    max-height: 0;
    transition: opacity 200ms, max-height 200ms;
  }
  
  
  .only-height-enter {
    opacity: 1;
    max-height: 0;
  }
  .only-height-enter-active {
    opacity: 1;
    max-height: 500px;
    transition: opacity 200ms, max-height 200ms;
  }
  .only-height-exit {
    opacity: 1;
    max-height: 500px;
    transition: opacity 200ms, max-height 200ms;
  }
  .only-height-exit-active {
    opacity: 1;
    max-height: 0;
    transition: opacity 200ms, max-height 200ms;
  }
  
  .content{
    overflow: hidden;
  }