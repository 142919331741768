body {
  margin: 0;
  font-family: "BPG Nino Medium", sans-serif;
  overflow-x: hidden !important;
}

:root {
  --backgroundTheme: #1c252e;
  --textTheme: #fff;
  --panelBg: #0f0c29;
  --panelDim: #252849;
}

body {
  background: var(--backgroundTheme);
}

.page {
  min-height: 100vh;
  position: absolute;
  width: 100vw;
  background-color: var(--panelDim);
}

html {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
}

.dark {
  background-color: var(--backgroundTheme) !important;
}

.grid-page {
  display: grid;
  grid-template-columns: 20% 80%;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
  -webkit-transition: opacity 300ms linear 150ms;
  -moz-transition: opacity 300ms linear 150ms;
  -ms-transition: opacity 300ms linear 150ms;
  -o-transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
  -webkit-transition: opacity 150ms linear;
  -moz-transition: opacity 150ms linear;
  -ms-transition: opacity 150ms linear;
  -o-transition: opacity 150ms linear;
}

.outside-wrapper {
  transition-duration: 0.4s;
  background: #000014;
  z-index: 99;
  -webkit-box-shadow: 0px 0px 0 1000vw rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 0 1000vw rgba(0, 0, 0, 0.75);
  max-height: 90vh;
  overflow: auto;
  box-shadow: 0px 0px 0 1000vw rgba(0, 0, 0, 0.75);
  position: fixed;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.primary-button {
  transition-duration: 0.3s !important;
}

.primary-button:active {
  transform: scale(0.9);
}

.primary-button[disabled] {
  transform: none;
}

* {
  outline-style: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .grid-page {
    display: grid;
    grid-template-columns: auto;
  }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
