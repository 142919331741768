.books-page {
  background-color: #f3f3f3;
}

.manager-bookings-list {
  width: 80vw;
  margin-left: 20vw;
}

.manager-book-container th {
  font-weight: 1;
}

.red-button svg, .red-button span{
  filter: invert(27%) sepia(74%) saturate(6333%) hue-rotate(352deg) brightness(92%) contrast(129%);
}

.manager-book-container th button {
  margin-left: 10px;
}

.manager-book-container span {
  margin: auto;
}

.big-font {
  font-size: 16px;
  padding-left: 8px 12px;
  margin-top: 15px;
}

.th-flex {
  display: flex;
}

.new-time-container {
  display: flex;
  flex-direction: column;
}

.new-time-container > div{
  display: flex;
}

@media (max-width: 768px) {
  .manager-bookings-list {
    width: 100vw;
    margin: 0;
  }
}
