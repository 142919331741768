.record-tr input {
  max-width: 130px;
}

.tr-edit {
  float: right;
  cursor: pointer;
  width: 30px;
  margin-top: 2px;
}

.tr-delete {
  float: right;
  cursor: pointer;
  width: 35px;
}

.block{
  display: block;
}

.select-container{
  position: relative;
  width: 0;
  display: block;
}

.pointer{
  cursor: pointer;
}

.px70 {
  width: 70px;
}

.flex-td {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store-record {
  display: flex;
}

.store-record input {
  width: 100px;
}

.price-td {
  max-width: 50px;
}

.price-td input {
  max-width: 50px;
}
