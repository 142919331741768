.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8fafe;
}

.login-form {
  display: inline-flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 30px 50px 40px 50px;
  align-items: center;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
}

.login-form h2 {
  margin: 0 0 20px 0;
  color: rgb(61, 61, 61);
}

.login-btn{
  background-size: 12% !important;
}

.login-form input {
  font-size: 20px;
  width: 100%;
  border: 1px solid rgb(202, 202, 202);
  padding: 16px;
  margin: 0 0 15px 0;
  color: rgb(53, 53, 53);
}

.login-form button {
  background-color: #4272d8;
  color: #ffffff;
  font-size: 17px;
  padding: 12px;
  border: none;
  width: 100%;
  cursor: pointer;
}

.login-form .gold-switch-item:not(.gold-chosen){
  color: gray;
}

.gold-switch {
  display: flex;
  margin: 10px 0;
}

.gold-switch-item {
  padding: 16px;
  color: #ffffff;
  transition-duration: 0.3s;
  cursor: pointer;
}

.gold-chosen {
  background-color: rgb(255, 166, 0);
  color: #ffffff;
}

@media (max-width: 768px) {
  .login-form {
    box-sizing: border-box;
    max-width: 90vw;
  }

  .login-form input {
    max-width: 100%;
    box-sizing: border-box;
  }
}
