.sale-item {
  display: flex;
  background-color: #0c0920;
  padding: 1em;
  margin: 1em;
}

.sale-item span {
  margin: 0 10px;
  color: #fff;
}
