.auth-page {
  background-color: var(--backgroundTheme);
  height: 100%;
  width: 100%;
}

.auth-form-- {
  display: flex;
  padding-top: 3rem;
  flex-direction: column;
  justify-content: center;
  color: var(--textTheme);
  max-width: 100vw;
}

.top-left-logo{
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
}

.top-left-logo img{
  width: 70px;
}

.auth-form {
  display: flex;
  background: var(--backgroundTheme);
  padding: 5rem;
  z-index: 0;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
}

.auth-flat {
  width: 30vw;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

.auth-flat .flat-img {
  height: 80%;
  width: 80%;
  margin: 20px;
}

.auth-logo{
  width: 6rem !important;
}

.agreement {
  margin-top: 1.3rem;
}

.auth-btn-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.auth-btn-container span {
  font-size: 0.8em;
  margin-top: 0.3em;
}

.auth-btn-container span a {
  text-decoration: none;
  color: #fff;
}

input[type="chechbox"] {
  cursor: pointer;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .auth-form {
    flex-direction: column;
    padding: 60px 40px 40px 40px;
    box-sizing: border-box;
    min-height: 100%;
  }

  .auth-form--{
    margin-top: 12rem;
    position: relative;
  }

  .auth-flat {
    max-height: 40vh;
    margin: 40px 0;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .auth-flat img {
    max-width: 55vw;
  }
}


@media(max-width: 424px){
  .auth-form--{
    max-width: 80vw;
    margin-top: 5rem;
  }
}