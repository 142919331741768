.services-page-content {
  background-color: var(--panelDim);
  padding-top: 20px;
}

.service-- {
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
  background-color: var(--panelBg);
  padding: 15px;
  color: var(--textTheme);
  display: flex;
  justify-content: space-between;
  margin: 10px 30px;
}

.service-- span {
  font-size: 18px;
}

.service-info > span {
  margin-right: 20px;
}

.service-name {
  margin-right: 15px;
}

.delete {
  background-color: rgb(255, 0, 0);
  background-image: url("./icons/bin.svg");
}

.centered {
  text-align: center;
}

.add-service-modal {
  display: flex;
  flex-direction: column;
}

.modal-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.modal-input span {
  margin-bottom: 5px;
}

.modal-input input {
  font-size: 18px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgb(199, 199, 199);
  transition-duration: 0.3s;
}

.modal-input select {
  font-size: 17px;
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 100%;
}

.modal-input input:focus {
  border-color: rgb(151, 151, 151);
}

.service-btn {
  background-repeat: no-repeat;
  background-size: 60%;
  height: 30px;
  width: 30px;
  background-position: center;
}

.edit {
  background-color: rgb(255, 217, 0);
  margin-left: 5px;
  background-image: url("./icons/edit.svg");
}

@media(max-width: 768px){
  .edit{
    margin-left: 0;
    margin-top: 3px;
  }
  .services-page-content{
    padding-top: 50px;
  }
}