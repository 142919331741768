.loading-button {
  color: rgba(0, 0, 0, 0) !important;
  background-image: url("./icons/loading.svg");
  background-position: center;
  background-size: 25%;
  background-repeat: no-repeat;
}

.loading-container {
  text-align: center;
  margin: 10px 0;
}

.loading-container img {
  width: 10vw;
}
