.users-page-content {
  background-color: var(--panelDim);
}

.users-page-content .user-modal,
.manager-books-page .user-modal {
  background: var(--panelBg) !important;
  color: var(--textTheme);
  max-height: unset !important;
}

.light-bg {
  display: grid;
  grid-template-columns: 50% 50%;
}

.light-bg > div {
  display: flex;
  flex-direction: column;
  margin: 10px;
  color: var(--textTheme);
}

.light-bg > div > input,
.light-bg > div > select {
  padding: 10px;
  box-sizing: border-box;
  height: 50px;
}

@media (max-width: 768px) {
  .users-page-content {
    padding-top: 50px;
  }
}
