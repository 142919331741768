.part {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.part img {
  width: 10vw;
}

.fa-button {
  background-color: var(--gold);
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.fa-button svg {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .part img {
    width: 30vw;
  }
}
