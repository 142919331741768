.contact {
  width: 100vw;
  height: 100vh;
  color: var(--textTheme);
}

.contact-header-wrapper {
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #ecc903;
  justify-content: center;
  color: #ffffff;
}

.contact-header {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.contact-part {
  text-align: center;
  position: relative;
  padding: 15px 0;
}

.contact-part img {
  width: 60px;
  position: absolute;
  bottom: 0;
}

.contact-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 50px;
}

.contact-info-img-container {
  margin-bottom: 35px;
}

.contact-info-img-container img {
  width: 80%;
  height: auto;
  object-fit: cover;
}

.contact-icon {
  width: 1.4em;
  height: auto;
  margin-right: 10px;
  vertical-align: middle;
}

.contact-info-details {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr 1fr;
}

.contact-icon img {
  width: 100%;
  height: 100%;
  min-width: 20px;
  object-fit: cover;
  filter: invert(1);
}

.contact-address {
  grid-column: 1 / span 2;
}

.contact-info-item {
  display: flex;
  align-items: center;
}

.contact-info-item p {
  font-size: 1.1em;
  vertical-align: middle;
}

/* .contact-form-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
 */

.contact-input-container p {
  display: inline-block;
  margin-bottom: 5px;
}
.contact-input {
  width: 100%;
  font-size: 1.1em;
  padding: 13px 5px 13px 13px;
  margin: auto;
  margin-bottom: 15px;
  border: none;
  border-bottom: 1px solid rgb(207, 210, 219);
  background: var(--backgroundTheme);
  color: var(--textTheme);
  outline: none;
  border-radius: 0;
  transition-duration: 0.3s;
}
/*
form{
    padding: 0 20%;
}
*/

.contact-input:focus {
  border-color: goldenrod;
}

.contact-form-btn {
  width: 100%;
  padding: 10px 0;
  text-align: center;
}

.contact-form-btn button {
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: rgb(255, 148, 92);
  border: 1px solid transparent;
  font-size: 1.1em;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 0.4s;
}

.contact-form-btn button:hover {
  color: rgb(255, 148, 92);
  background-color: transparent;
  border: 1px solid rgb(255, 148, 92);
}

.contact-form-header {
  text-align: center;
}

.contact-info-detail {
  padding-left: 12%;
  padding-bottom: 7%;
}

.contact-info-item-name h4 {
  display: inline;
}

@media (max-width: 768px) {
  .contact-wrapper {
    grid-template-columns: 100%;
    padding: 15px;
  }

  .contact-part img {
    display: none;
  }

  .contact-info-img-container {
    text-align: center;
  }

  .contact-info-detail:nth-last-child(){
    width: 100%;
  }
}
