.switch-active {
  background-color: rgb(0, 162, 255);
  color: #ffffff;
  padding: 2px;
}

.service-switch {
  cursor: pointer;
}

.service-name-input{
  border: 1px solid gray;
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-switch > div{
  width: 20px;
}