.profile-pic-wrapper {
  height: 200px;
  width: 200px;
  border: 2px solid gray;
  margin: 0 20px;
  transform: translateY(10%);
  position: relative;
  border-radius: 100px;
  overflow: hidden;
}

.profile-pic-wrapper:hover .upload-pic {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.upload-pic {
  position: absolute;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  opacity: 0;
  transition-duration: 0.3s;
  pointer-events: none;
}

.upload-pic span {
  margin-right: 5px;
}

.profile-pic-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profile-pic {
  display: flex;
  align-items: flex-end;
}

.profile-pic h1 {
  color: #ffffff;
}

.pics-grid {
  justify-items: center;
  padding-top: 20px;
  background: var(--backgroundTheme);
}

.gallery-item-wrapper {
  position: relative;
}

.gallery-item-wrapper .delete-book {
  bottom: 60px;
  right: 20px;
}

.stylist-nav {
  display: flex;
  background: var(--backgroundTheme);
  padding-top: 20px;
  justify-content: space-around;
  color: var(--textTheme);
}

.stylist-nav div{
  padding-bottom: 5px;
  cursor: pointer;
}

.stylist-nav-active {
  color: gold;
  border-bottom: 1px solid gold;
}

@media (max-width: 768px) {
  .gallery-item-wrapper .delete-book {
    bottom: 30px;
    right: 30px;
  }
}

.gallery-item-wrapper:hover .delete-book {
  opacity: 1;
  pointer-events: all;
}

.upload-photo {
  height: 250px;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 40px;
  display: flex;
  color: var(--textTheme);
  justify-items: center;
  align-items: center;
  cursor: pointer;
}

.upload-photo-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.gallery-item-content {
  display: flex;
  justify-content: center;
}

.gallery-item-content button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.salary-header{
  color: var(--textTheme);
  text-align: center;
  margin: 25px 0 0 0;
}

.stylist-salary-container .total{
  color: var(--textTheme);
}



.stylist-salary-container .cv-vipped-item{
  background-color: var(--gold) !important;
}

.stylist-salary-container{
  min-height: 40vh;
  padding-bottom: 20px;
}

.salary-item-container{
  display: flex;
  color: #ffffff;
  background: rgb(0, 0, 24);
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
}

.profile-header{
  position: relative;
}

.margined-span{
  margin-right: 20px;
}

.profile-name{
  display: flex;
}

.toggle-wrapper{
  display: flex;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.toggle-wrapper span{
  color: var(--textTheme);
  padding-right: 10px;
}

@media (max-width: 768px) {
  .profile-pic-wrapper {
    transform: none;
    margin: 10px;
  }

  .pics-grid .upload-photo {
    margin: 20px;
  }

  .pics-grid .gallery-item {
    margin: 20px;
  }

  .cv-vip-container{
    flex-direction: column;
  }

  .profile-pic{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
  }
}

@media(max-width: 500px){
  .toggle-wrapper{
    bottom: unset;
    top: 15px;
  }
}