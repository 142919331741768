.user-nav-bar {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 9;
  padding: 1rem 2rem;
  background: #12171d;
}

.user-nav-bar-logo {
  width: 100px;
}

.resp-logo {
  display: none;
  background: #12171d;
}

.user-nav-bar-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.user-nav-bar-ul {
  padding-right: 30%;
}

.user-nav-bar-ul ul {
  display: flex;
}

.user-nav-bar-ul ul li {
  display: inline-flex;
  padding: 8px 12px;
}

.user-nav-bar-ul ul li a {
  text-decoration: none;
  color: #d09d3a;
}

.user-nav-bar-signup{
  display: flex;
  align-items: center;
}

.user-nav-bar-signup button {
  border: 1px solid #fa8f77;
  padding: 12px 18px;
  border-radius: 25px;
  background: transparent;
  color: #d09d3a;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  transition-duration: 0.3s;
}

.user-nav-bar-signup button:hover {
  background-color: #d79838;
  color: #ffffff;
}

.link-2 a {
  color: #d09d3a;
  text-decoration: none !important;
}

@media(max-width: 1024px){
  .user-nav-bar-ul{
    padding-right: 15%;
  }
}

@media (max-width: 768px) {
  .user-nav-open {
    right: 0 !important;
  }

  .resp-logo {
    display: flex;
    position: fixed;
    top: 0px;
    transition-duration: 0.3s;
    left: 0px;
    margin: 0 !important;
    padding: 10px;
    width: 100vw;
    box-sizing: border-box;
    max-width: 100vw;
    z-index: 9;
  }

  .user-nav-scrolled {
    box-shadow: 0 12.5px 10px rgba(0, 0, 0, 0.02),
      0 100px 80px rgba(0, 0, 0, 0.04);
  }

  .resp-logo img {
    width: 50px;
  }

  .user-hamburger {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 99;
  }

  .user-hamburger > div {
    background-color: #d79838;
  }

  .user-nav-bar {
    position: fixed;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    background: var(--backgroundTheme);
    flex-direction: column;
    justify-content: flex-start;
    right: -100vw;
    transition-duration: 0.3s;
  }

  .user-nav-bar > div {
    margin: 20px 0;
  }

  .user-nav-bar-ul {
    padding: 0;
  }

  .user-nav-bar-ul ul {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .user-nav-bar-ul ul li {
    display: flex;
    justify-content: center;
  }

  .user-nav-bar-ul a {
    font-size: 19px;
  }

  .user-nav-bar-signup button {
    font-size: 17px;
    margin: 10px 0 0 0;
  }

  .user-nav-bar-signup {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media(max-width: 424px){
  .user-nav-bar-ul{
    margin: 10px 0 0 0 !important;
  }

  .user-nav-bar-logo{
    display: none;
  }

  .user-nav-bar-signup{
    margin: 0 !important;
  }
}