.user-bookings {
  background-color: var(--backgroundTheme);
  min-height: 100vh;
}

.user-bookings-header {
  height: 30vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../assets/salon.jpg");
  background-size: 100%;
  background-position: center 40%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-bookings-header h2 {
  margin: 0;
  color: #ffffff;
}

.bookings-info{
  display: flex;
  justify-content: center;
  align-items: center;
  filter: invert(1);
  min-height: 10rem;
}

.bookings-info h1{
  display: inline-block;
  margin-left: 2rem;
}

.blue-border {
  border-color: rgb(87, 87, 204) !important;
}

.blue {
  color: rgb(87, 87, 204) !important;
}

.user-book {
  position: relative;
}

.user-book h4 {
  margin: 10px 0;
}

.date-span {
  margin: 10px 0;
  color: var(--gold);
}

.delete-book {
  position: absolute;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition-duration: 0.3s;
}

.user-book:hover .delete-book {
  opacity: 1;
  pointer-events: all;
}

.user-book {
  display: flex;
  flex-direction: column;
  background-color: #000014;
  color: var(--textTheme);
  margin: 20px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4.1px 10px rgba(0, 0, 0, 0.04), 0 33px 80px rgba(0, 0, 0, 0.08);
}

.user-book-info {
  display: flex;
  flex-direction: column;
}

.user-book-info {
  border-left: 2px solid var(--gold);
  padding-left: 20px;
}
