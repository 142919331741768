.modal-container {
  background-color: var(--panelBg);
  color: var(--textTheme);
  padding: 20px;
  min-width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
}

.modal-services,
.modal-price {
  display: flex;
  align-items: flex-start;
  width: 60%;
  justify-content: space-between;
  flex-direction: column;
}

.item-container button{
  margin: 5px
}

.modal-ok{
  background-size: 45% !important;
}

.modal-services input,
.modal-price input,
.select-w{
  width: 100% !important;
}

.modal-services h4, .modal-price h4{
  margin: 10px 0;
}

.modal-ok{
  margin-top: 30px;
}

.modal-grid{
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
}

.modal-services, .modal-price{
  width: 80%;
}

.modal-services select {
  padding: 10px;
}

.modal-price input {
  width: 100px;
  padding: 10px;
}

.modal-x {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  cursor: pointer;
}

.new-emp{
  margin: 10px 0 30px 0;
}

.add-emp-btn{
  margin-top: 15px;
}

.service{
  display: flex;
}

.service input{
  width: 50px;
}

.prof-select{
  margin-bottom: 20px;
}

.store-part{
  margin-top: 30px;
}

.item-container{
  display: flex;
  border: 1px solid gray;
  align-items: center;
  justify-content: space-between;
  justify-content: space-between;
}

.store-add{
  margin-top: 5px;
}

.add-grid > div{
  display: flex;
  flex-direction: column;
}

.add-grid > div > input{
  padding: 10px;
}

.add-grid > div > select{
  padding: 10px;
}

.store-add > *{
  box-sizing: border-box;
  height: 30px;
}

.item-container input{
  border: none;
  border-left: 1px solid gray;
  height: 100%;
  background: transparent;
  color: var(--textTheme);
  max-width: 100px;
  padding: 10px;
}

.item-container .price{
  min-width: 50px;
  width: 50px;
  max-width: 50px;
  display: inline-block;
}

.item-info{
  padding: 0 20px 0 10px;
}

@media(max-width: 768px){
  .modal-container{
    width: 80vw;
  }
}