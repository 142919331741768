.stylist-info-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: #f7f7f7;
  height: calc(100vh - 72px - 2rem);
  position: relative;
  overflow: hidden;
}

.stylist-img {
  position: relative;
  height: 100%;
  max-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.active-time {
  background-color: var(--gold);
  color: #ffffff !important;
  border-color: #ffffff;
}

.active-time:hover {
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.stylist-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  background: rgba(0, 4, 24, 0.897);
}

.stylist-info .stylist-name {
  font-size: 45px;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
}

.gallery-item {
  height: 250px;
  width: 250px;
  overflow: hidden;
  margin: 40px 0;
}

.stylist-bio {
  color: var(--textTheme);
  display: flex;
  flex-direction: column;
}

.stylist-bio .golden-button {
  max-width: 10em;
}

.stylist-bio h3 {
  margin: 5px 0;
}

.stylist-status {
  margin-top: 15px;
}

.gallery-item img {
  width: 100%;
  transition-duration: 0.3s;
  height: 100%;
  object-fit: cover;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.transparent {
  background-color: transparent !important;
  max-height: 80vh !important;
  max-width: 100vw !important;
  display: flex;
  padding: 0 !important;
  margin: 30px !important;
  justify-content: center;
}

.transparent img {
  max-width: 80% !important;
  max-height: 70vh !important;
}

.transparent .user-modal-x {
  display: none;
}

.book-modal {
  max-height: 90vh !important;
  max-width: 60vw !important;
  color: var(--textTheme);
}
.book-hours {
  max-height: 40vh;
  overflow-y: scroll;
  padding-right: 5px;
}

.booked-hour {
  background: red;
  pointer-events: none;
  cursor: no-drop !important;
}

.book-container {
  display: flex;
}

.book-container .rdrCalendarWrapper {
  background-color: var(--backgroundTheme);
}

.book-container span {
  color: var(--textTheme);
}

.book-container .rdrDayDisabled {
  background-color: rgb(5, 0, 20);
}

.book-hours {
  margin-top: 30px;
}

.book-service {
  color: var(--textTheme);
}

.book-service select {
  padding: 8px 12px;
  background: var(--backgroundTheme);
  color: var(--textTheme);
  margin-left: 10px;
}

.book-hour {
  border: 1px solid var(--textTheme);
  margin: 2px 0;
  transition-duration: 0.3s;
  cursor: pointer;
  color: var(--textTheme);
  padding: 8px;
}

.book-hour:hover {
  border-color: var(--gold);
  color: var(--gold);
}

@media (max-width: 1024px) {
  .gallery-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .book-hours {
    display: flex;
    max-width: 90vw;
    overflow-y: hidden;
  }
  .stylist-info-grid {
    grid-template-columns: 100%;
    height: auto;
  }

  .stylist-info {
    padding-bottom: 50px;
  }

  .stylist-img {
    height: 70vh;
  }

  .book-container {
    flex-direction: column;
    align-items: center;
  }

  .book-modal {
    max-width: 100vw !important;
    max-height: 100vh !important;
    height: 100vh !important;
    border-radius: 0 !important;
    width: 100vw !important;
    padding-top: 60px !important;
    overflow: auto;
  }

}

@media (max-width: 550px) {
  .gallery-container {
    grid-template-columns: auto;
  }
}
