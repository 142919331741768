.user-container {
  display: flex;
  background-color: var(--panelBg);
  color: var(--textTheme);
  margin: 10px 20px;
  padding: 15px;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
}

.user-container .user-info > span {
  margin-right: 15px;
  font-size: 16px;
}

.user-props{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.user-info {
  display: grid;
  width: 100%;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
}

.user-container a {
  color: var(--gold);
}

.user-history-item span {
  font-size: 17px;
  margin-right: 15px;
}

.user-arrow {
  float: right;
  filter: invert(1);
  width: 20px;
  transform: rotate(90deg);
  transition-duration: 0.3s;
  cursor: pointer;
}

.user-arrow-down {
  transform: rotate(-90deg);
}

.fake-margin {
  display: inline-flex;
  min-height: 30px;
  justify-content: center;
  flex-direction: column;
  align-self: center;
}

.fake-margin span {
  margin: 5px 0 15px 0;
}

.fake-border {
  height: 2px;
  width: 200px;
  background-color: rgb(80, 80, 80);
}
