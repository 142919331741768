.pass-change-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--panelDim);
}

.pass-change-form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4.5px 3.6px rgba(0, 0, 0, 0.024),
    0 12.5px 10px rgba(0, 0, 0, 0.035), 0 30.1px 24.1px rgba(0, 0, 0, 0.046),
    0 100px 80px rgba(0, 0, 0, 0.07);
}

.pass-change-form input {
  font-size: 18px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgb(199, 199, 199);
  transition-duration: 0.3s;
}

.pass-change-form > * {
  margin: 10px 0;
}

.per20{
  background-size: 15%;
}