.whyus-container {
  background-color: gold;
  color: rgb(46, 46, 46);
  padding: 2rem 0 4rem 0;
}

.whyus-container h2 {
  text-align: center;
  padding: 2rem 0;
}

.whyus-flex > div {
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
}

.whyus-flex {
  display: flex;
}

.whyus-flex img {
  width: 80px;
}

.popular-stylist-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--textTheme);
}

@media (max-width: 768px) {
  .whyus-flex {
    flex-direction: column;
  }

  .whyus-flex > div{
    align-items: center;
  }

  .popular-stylist-header-new p{
    display: none;
  }
}
