.profile-header {
  position: relative;
  min-height: 30vh;
  background: linear-gradient(to right, rgb(255, 221, 26), rgb(255, 217, 0));
}

.profile-header > h1 {
  position: absolute;
  bottom: 20px;
  color: #ffffff;
  padding-left: 20px;
  margin: 0;
}

.profile-item {
  display: flex;
  color: #ffffff;
  margin: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  transition-duration: .3s;
}

.profile-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 50px;
}

.profile-item:hover{
  transform: translateY(-5px);
}

.profile-info h2 {
  margin: 5px 0;
}

.profile-info h4 {
  margin: 5px 0;
}

@media(max-width: 424px){
  .profile-item{
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
  }
}