.loading-button {
  color: rgba(0, 0, 0, 0) !important;
  background-image: url(/static/media/loading.0505e320.svg);
  background-position: center;
  background-size: 25%;
  background-repeat: no-repeat;
}

.loading-container {
  text-align: center;
  margin: 10px 0;
}

.loading-container img {
  width: 10vw;
}

.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8fafe;
}

.login-form {
  display: inline-flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 30px 50px 40px 50px;
  align-items: center;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
}

.login-form h2 {
  margin: 0 0 20px 0;
  color: rgb(61, 61, 61);
}

.login-btn{
  background-size: 12% !important;
}

.login-form input {
  font-size: 20px;
  width: 100%;
  border: 1px solid rgb(202, 202, 202);
  padding: 16px;
  margin: 0 0 15px 0;
  color: rgb(53, 53, 53);
}

.login-form button {
  background-color: #4272d8;
  color: #ffffff;
  font-size: 17px;
  padding: 12px;
  border: none;
  width: 100%;
  cursor: pointer;
}

.login-form .gold-switch-item:not(.gold-chosen){
  color: gray;
}

.gold-switch {
  display: flex;
  margin: 10px 0;
}

.gold-switch-item {
  padding: 16px;
  color: #ffffff;
  transition-duration: 0.3s;
  cursor: pointer;
}

.gold-chosen {
  background-color: rgb(255, 166, 0);
  color: #ffffff;
}

@media (max-width: 768px) {
  .login-form {
    box-sizing: border-box;
    max-width: 90vw;
  }

  .login-form input {
    max-width: 100%;
    box-sizing: border-box;
  }
}

.navigation-container {
  width: 100%;
  position: relative;
  height: 100vh;
  z-index: 9;
  padding: 10px;
  background-color: var(--panelBg);
}

.navigation-logo {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}

.navigation-logo h2 {
  color: var(--textTheme);
  padding-left: 40px;
  padding-bottom: 10px;
  margin: 0;
  border-bottom: 1px solid white;
  width: 100%;
}

.nav-item {
  display: flex;
  padding: 10px 20px;
  margin: -10px 0;
}

.nav-item:nth-child(1) {
  margin-top: 10px;
}

.nav-item img {
  width: 20px;
  margin-right: 15px;
  -webkit-filter: invert(59%) sepia(13%) saturate(657%) hue-rotate(185deg)
    brightness(88%) contrast(90%);
          filter: invert(59%) sepia(13%) saturate(657%) hue-rotate(185deg)
    brightness(88%) contrast(90%);
}

.nav-active {
  background: var(--panelDim);
  border-radius: 8px;
}

.nav-active img {
  -webkit-filter: invert(42%) sepia(53%) saturate(1188%) hue-rotate(194deg)
    brightness(88%) contrast(90%);
          filter: invert(42%) sepia(53%) saturate(1188%) hue-rotate(194deg)
    brightness(88%) contrast(90%);
}

.nav-item-wrapper a {
  text-decoration: none;
  color: #c0c0c0 !important;
}
.nav-active a {
  color: #4471d8 !important;
}

.nav-item {
  transition-duration: 0.3s;
}

.user-nav-bar {
  background-color: var(--backgroundTheme);
}

.nav-item:not(.nav-active):hover {
  -webkit-filter: invert(42%) sepia(53%) saturate(1188%) hue-rotate(194deg)
    brightness(88%) contrast(90%);
          filter: invert(42%) sepia(53%) saturate(1188%) hue-rotate(194deg)
    brightness(88%) contrast(90%);
}

.hamburger-button {
  display: none;
}

.gold-active {
  background-color: rgb(255, 217, 0);
  color: #ffffff;
  padding: 10px;
  transform: translateX(-10px);
  border-radius: 10px;
}

.gold-active img {
  -webkit-filter: invert(99%) sepia(0%) saturate(3085%) hue-rotate(36deg)
    brightness(125%) contrast(98%);
          filter: invert(99%) sepia(0%) saturate(3085%) hue-rotate(36deg)
    brightness(125%) contrast(98%);
}

.gold-active:hover {
  -webkit-filter: none !important;
          filter: none !important;
  background-color: rgb(255, 223, 40);
}

.gold-nav {
  cursor: pointer;
}

.nav-wrapper {
  position: relative;
}

.navigation-container {
  position: fixed;
  width: 20%;
}

.gold-nav-switch {
  justify-content: center;
}

.gold-nav-switch > div {
  height: 50px;
  width: 50px;
  text-align: center;
}

.gold-nav-switch > div:not(.gold-chosen):hover {
  background-color: rgba(0, 0, 0, 0.171);
}

@media (max-width: 768px) {
  .hamburger-button {
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .nav-opened {
    left: 0 !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.411);
  }

  .hamburger-button div {
    height: 2px;
    width: 30px;
    margin: 4px 0;
    background-color: #000000;
    transition-duration: 0.3s;
  }

  .hamburger-opened div:nth-child(1) {
    transform: rotate(45deg) translate(4px, 10px);
  }

  .hamburger-opened div:nth-child(2) {
    opacity: 0;
  }

  .hamburger-opened div:nth-child(3) {
    transform: rotate(-45deg) translate(3px, -11px);
  }

  .navigation-container {
    position: fixed;
    left: -200vw;
    top: 0;
    width: 70%;
    height: 100vh;
    transition-duration: 0.3s;
  }
}

.switch-active {
  background-color: rgb(0, 162, 255);
  color: #ffffff;
  padding: 2px;
}

.service-switch {
  cursor: pointer;
}

.service-name-input{
  border: 1px solid gray;
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-switch > div{
  width: 20px;
}
.modal-container {
  background-color: var(--panelBg);
  color: var(--textTheme);
  padding: 20px;
  min-width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
}

.modal-services,
.modal-price {
  display: flex;
  align-items: flex-start;
  width: 60%;
  justify-content: space-between;
  flex-direction: column;
}

.item-container button{
  margin: 5px
}

.modal-ok{
  background-size: 45% !important;
}

.modal-services input,
.modal-price input,
.select-w{
  width: 100% !important;
}

.modal-services h4, .modal-price h4{
  margin: 10px 0;
}

.modal-ok{
  margin-top: 30px;
}

.modal-grid{
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
}

.modal-services, .modal-price{
  width: 80%;
}

.modal-services select {
  padding: 10px;
}

.modal-price input {
  width: 100px;
  padding: 10px;
}

.modal-x {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  cursor: pointer;
}

.new-emp{
  margin: 10px 0 30px 0;
}

.add-emp-btn{
  margin-top: 15px;
}

.service{
  display: flex;
}

.service input{
  width: 50px;
}

.prof-select{
  margin-bottom: 20px;
}

.store-part{
  margin-top: 30px;
}

.item-container{
  display: flex;
  border: 1px solid gray;
  align-items: center;
  justify-content: space-between;
  justify-content: space-between;
}

.store-add{
  margin-top: 5px;
}

.add-grid > div{
  display: flex;
  flex-direction: column;
}

.add-grid > div > input{
  padding: 10px;
}

.add-grid > div > select{
  padding: 10px;
}

.store-add > *{
  box-sizing: border-box;
  height: 30px;
}

.item-container input{
  border: none;
  border-left: 1px solid gray;
  height: 100%;
  background: transparent;
  color: var(--textTheme);
  max-width: 100px;
  padding: 10px;
}

.item-container .price{
  min-width: 50px;
  width: 50px;
  max-width: 50px;
  display: inline-block;
}

.item-info{
  padding: 0 20px 0 10px;
}

@media(max-width: 768px){
  .modal-container{
    width: 80vw;
  }
}
.part {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.part img {
  width: 10vw;
}

.fa-button {
  background-color: var(--gold);
  color: #ffffff;
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.fa-button svg {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .part img {
    width: 30vw;
  }
}

.my-node-enter {
    opacity: 0;
    max-height: 0;
  }
  .my-node-enter-active {
    opacity: 1;
    max-height: 500px;
    transition: opacity 200ms, max-height 200ms;
  }
  .my-node-exit {
    opacity: 1;
    max-height: 500px;
  }
  .my-node-exit-active {
    opacity: 0;
    max-height: 0;
    transition: opacity 200ms, max-height 200ms;
  }
  
  
  .only-height-enter {
    opacity: 1;
    max-height: 0;
  }
  .only-height-enter-active {
    opacity: 1;
    max-height: 500px;
    transition: opacity 200ms, max-height 200ms;
  }
  .only-height-exit {
    opacity: 1;
    max-height: 500px;
    transition: opacity 200ms, max-height 200ms;
  }
  .only-height-exit-active {
    opacity: 1;
    max-height: 0;
    transition: opacity 200ms, max-height 200ms;
  }
  
  .content{
    overflow: hidden;
  }
.employee-item {
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
  width: 100%;
  background-color: var(--panelBg);
  margin: 10px 0;
  color: var(--textTheme);
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 4px;
}

.employee-info {
  display: flex;
  align-items: center;
  font-size: 19px;
}

.employee-info > div {
  margin: 0 10px;
}

.employee-add{
  cursor: pointer;
}

.employee-status {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.employee-status.free {
  color: rgb(0, 185, 0);
}

.employee-status.not-free {
  color: rgb(248, 0, 0);
}

.status-dot {
  height: 6px;
  width: 6px;
  border-radius: 4px;
  margin-right: 5px;
  transform: translateY(1px);
  transition-duration: 0.3s;
}

.status-dot.red {
  background-color: rgb(248, 0, 0);
}

.status-dot.green {
  background-color: rgb(0, 185, 0);
}

.employee-status span {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition-duration: 0.3s;
}

.arrow {
  width: 20px;
  margin-left: 20px;
  transform: rotate(90deg);
  -webkit-filter: invert(1);
          filter: invert(1);
  transition-duration: 0.3s;
  cursor: pointer;
}

.arrow-y {
  transform: rotate(-90deg);
}

.employee-info-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.employee-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.emp-down {
  position: relative;
  text-align: left;
  padding-left: 10px;
}

.del-btn {
  font-size: 13px !important;
  background-color: red !important;
}

.bottom-right {
  display: flex;
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.edit-btn {
  background-color: #ffd900 !important;
  margin-left: 5px;
  background-image: url(/static/media/edit.77e63c93.svg);
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  .employee-item {
    flex-direction: column;
    max-width: 80vw;
    display: inline-flex;
  }

  .employee-info {
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .employee-info > div{
    margin: 5px 0;
  }

  .employee-status{
    display: flex;
    justify-content: center;
  }

  .employee-info-wrapper {
    flex-direction: column;
  }
}

.employees-page-content {
  background-color: var(--panelDim);
  padding: 20px;
  text-align: center;
}

.add-stylist {
  cursor: pointer;
  margin-top: 0 !important;
}

.primary-button {
  background-color: #4471d8;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 18px;
  padding: 0.4rem 0.8rem;
}

.services-page-content {
  background-color: var(--panelDim);
  padding-top: 20px;
}

.service-- {
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
  background-color: var(--panelBg);
  padding: 15px;
  color: var(--textTheme);
  display: flex;
  justify-content: space-between;
  margin: 10px 30px;
}

.service-- span {
  font-size: 18px;
}

.service-info > span {
  margin-right: 20px;
}

.service-name {
  margin-right: 15px;
}

.delete {
  background-color: rgb(255, 0, 0);
  background-image: url(/static/media/bin.b9089db0.svg);
}

.centered {
  text-align: center;
}

.add-service-modal {
  display: flex;
  flex-direction: column;
}

.modal-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.modal-input span {
  margin-bottom: 5px;
}

.modal-input input {
  font-size: 18px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgb(199, 199, 199);
  transition-duration: 0.3s;
}

.modal-input select {
  font-size: 17px;
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 100%;
}

.modal-input input:focus {
  border-color: rgb(151, 151, 151);
}

.service-btn {
  background-repeat: no-repeat;
  background-size: 60%;
  height: 30px;
  width: 30px;
  background-position: center;
}

.edit {
  background-color: rgb(255, 217, 0);
  margin-left: 5px;
  background-image: url(/static/media/edit.fbefbb35.svg);
}

@media(max-width: 768px){
  .edit{
    margin-left: 0;
    margin-top: 3px;
  }
  .services-page-content{
    padding-top: 50px;
  }
}
.manager {
  background-color: var(--panelBg);
  padding: 15px;
  color: var(--textTheme);
  margin: 10px 20px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
}

.manager span {
  font-size: 18px;
}


.managers-page-content {
  background-color: var(--panelDim);
  padding: 20px 0;
}

.managers-func{
  text-align: right;
}

@media(max-width: 768px){
  .managers-page-content{
    padding-top: 50px;
  }
}
.record-tr input {
  max-width: 130px;
}

.tr-edit {
  float: right;
  cursor: pointer;
  width: 30px;
  margin-top: 2px;
}

.tr-delete {
  float: right;
  cursor: pointer;
  width: 35px;
}

.block{
  display: block;
}

.select-container{
  position: relative;
  width: 0;
  display: block;
}

.pointer{
  cursor: pointer;
}

.px70 {
  width: 70px;
}

.flex-td {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store-record {
  display: flex;
}

.store-record input {
  width: 100px;
}

.price-td {
  max-width: 50px;
}

.price-td input {
  max-width: 50px;
}

.records-page-content {
  background-color: var(--panelDim);
  padding-bottom: 30px;
  overflow-x: auto !important;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: calc(100% - 40px);
  margin: 20px;
}

td {
  text-align: left;
  padding: 10px 8px;
}

th {
  color: #ffffff;
  padding: 10px 8px;
  text-align: left;
}

.tr-header {
  background-color: #0c0920 !important;
}


tr {
  background-color: var(--panelBg);
  color: #ffffff !important;
  border-bottom: 1px solid rgba(228, 228, 228, 0.685);
}

th{
  color: #ffffff !important;
}

.records-search {
  display: inline-flex;
}

.records-search-container {
  text-align: center;
  margin: 20px 0;
}

.records-search input {
  padding: 10px;
  height: 45px;
  border: 1px solid rgb(182, 182, 182);
  font-size: 17px;
}

.records-search button {
  height: 45px;
  width: 45px;
  background-image: url(/static/media/search.2aabfe60.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}

.user-container {
  display: flex;
  background-color: var(--panelBg);
  color: var(--textTheme);
  margin: 10px 20px;
  padding: 15px;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0 6.7px 5.3px rgba(0, 0, 0, 0.016),
    0 22.3px 17.9px rgba(0, 0, 0, 0.024), 0 100px 80px rgba(0, 0, 0, 0.04);
}

.user-container .user-info > span {
  margin-right: 15px;
  font-size: 16px;
}

.user-props{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.user-info {
  display: grid;
  width: 100%;
  justify-items: center;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
}

.user-container a {
  color: var(--gold);
}

.user-history-item span {
  font-size: 17px;
  margin-right: 15px;
}

.user-arrow {
  float: right;
  -webkit-filter: invert(1);
          filter: invert(1);
  width: 20px;
  transform: rotate(90deg);
  transition-duration: 0.3s;
  cursor: pointer;
}

.user-arrow-down {
  transform: rotate(-90deg);
}

.fake-margin {
  display: inline-flex;
  min-height: 30px;
  justify-content: center;
  flex-direction: column;
  align-self: center;
}

.fake-margin span {
  margin: 5px 0 15px 0;
}

.fake-border {
  height: 2px;
  width: 200px;
  background-color: rgb(80, 80, 80);
}

.users-page-content {
  background-color: var(--panelDim);
}

.users-page-content .user-modal,
.manager-books-page .user-modal {
  background: var(--panelBg) !important;
  color: var(--textTheme);
  max-height: unset !important;
}

.light-bg {
  display: grid;
  grid-template-columns: 50% 50%;
}

.light-bg > div {
  display: flex;
  flex-direction: column;
  margin: 10px;
  color: var(--textTheme);
}

.light-bg > div > input,
.light-bg > div > select {
  padding: 10px;
  box-sizing: border-box;
  height: 50px;
}

@media (max-width: 768px) {
  .users-page-content {
    padding-top: 50px;
  }
}

@media (max-width: 768px) {
  .store-page {
    grid-template-columns: auto;
  }
  .records-page-content {
    padding-top: 50px;
  }
  .nav-wrapper {
    position: absolute;
  }
}

.cv-radios {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.cv-vip-container {
  position: relative;
  display: flex;
  border: 1px solid rgb(105, 105, 105);
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
}

.cv-vipped-item p {
  color: #ffffff;
  position: relative;
  z-index: 1;
}

.cv-radios p{
  text-align: center;
}

.cv-vipped .cv-vip-absolute-bg {
  transform: translateX(100%);
}

.cv-vip-absolute-bg {
  height: 100%;
  width: 50%;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  background-color: var(--gold);
  transition-duration: 0.3s;
}

.cv-vip-item p {
  margin: 0;
  padding: 10px 5px;
  transition-duration: 0.3s;
}

.cv-vip-item {
  width: 150px;
}


.switches{
    display: flex;
    justify-content: center;
}

.switches > div{
    margin: 20px;
}
.pass-change-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--panelDim);
}

.pass-change-form {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4.5px 3.6px rgba(0, 0, 0, 0.024),
    0 12.5px 10px rgba(0, 0, 0, 0.035), 0 30.1px 24.1px rgba(0, 0, 0, 0.046),
    0 100px 80px rgba(0, 0, 0, 0.07);
}

.pass-change-form input {
  font-size: 18px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid rgb(199, 199, 199);
  transition-duration: 0.3s;
}

.pass-change-form > * {
  margin: 10px 0;
}

.per20{
  background-size: 15%;
}
.total .cv-vip-item{
    padding: 10px;
    text-align: center;
    transition-duration: .3s;
}

.total .cv-vipped-item{
    background-color: var(--gold);
    color: #ffffff;
}
.footer-top {
  background-color: #28323c;
  display: flex;
  justify-content: space-around;
  padding: 40px 0;
  max-width: 100vw;
  color: #ffffff;
}

.footer-top-part img {
  width: 80px;
}

.footer-top-part a{
  text-decoration: none;
  color: #fff;
}

.footer-top ul {
  list-style-type: none;
}

.footer-top ul li {
  margin: 10px 0;
}

.footer-bottom {
  background-color: #1c252e;
  display: flex;
  justify-content: space-around;
  padding: 30px 0;
  max-width: 100vw;
  color: gray;
}

.footer-bottom a {
  color: gold;
  text-decoration: none;
}

@media (max-width: 768px) {
  .footer-top {
    display: none;
  }
}

.user-badge {
  height: 40px;
  width: 40px;
  border-radius: 100px;
  background-color: var(--gold);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.user-badge span {
  color: #ffffff;
}

.user-menu-bar {
  position: absolute !important;
  left: unset !important;
  right: 10% !important;
  top: 120% !important;
  transform: none !important;
  box-shadow: 0px 0px 8px 0px rgba(88, 63, 63, 0.23) !important;
  border-radius: 4px !important;
  background-color: #ffffff;
}

.user-menu-bar ul {
  list-style-type: none;
  margin: 5px 0;
  padding: 0;
}
.user-menu-bar a {
  text-decoration: none;
  font-size: 18px;
  color: #000000;
}

.user-menu-bar ul li {
  padding: 6px 12px;
  display: flex;
  transition-duration: 0.3s;
  align-items: center;
}

.user-menu-bar ul li span {
  margin-left: 10px;
  color: var(--textTheme) !important;
}

.user-menu-bar ul li:hover {
  background-color: var(--gold);
  color: #ffffff !important;
}

.user-menu-bar ul li:hover span {
  color: #ffffff;
}

.bordered {
  border-top: 1px solid rgb(240, 240, 240);
}

.user-nav-bar {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 9;
  padding: 1rem 2rem;
  background: #12171d;
}

.user-nav-bar-logo {
  width: 100px;
}

.resp-logo {
  display: none;
  background: #12171d;
}

.user-nav-bar-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.user-nav-bar-ul {
  padding-right: 30%;
}

.user-nav-bar-ul ul {
  display: flex;
}

.user-nav-bar-ul ul li {
  display: inline-flex;
  padding: 8px 12px;
}

.user-nav-bar-ul ul li a {
  text-decoration: none;
  color: #d09d3a;
}

.user-nav-bar-signup{
  display: flex;
  align-items: center;
}

.user-nav-bar-signup button {
  border: 1px solid #fa8f77;
  padding: 12px 18px;
  border-radius: 25px;
  background: transparent;
  color: #d09d3a;
  cursor: pointer;
  margin-left: 10px;
  font-size: 16px;
  transition-duration: 0.3s;
}

.user-nav-bar-signup button:hover {
  background-color: #d79838;
  color: #ffffff;
}

.link-2 a {
  color: #d09d3a;
  text-decoration: none !important;
}

@media(max-width: 1024px){
  .user-nav-bar-ul{
    padding-right: 15%;
  }
}

@media (max-width: 768px) {
  .user-nav-open {
    right: 0 !important;
  }

  .resp-logo {
    display: flex;
    position: fixed;
    top: 0px;
    transition-duration: 0.3s;
    left: 0px;
    margin: 0 !important;
    padding: 10px;
    width: 100vw;
    box-sizing: border-box;
    max-width: 100vw;
    z-index: 9;
  }

  .user-nav-scrolled {
    box-shadow: 0 12.5px 10px rgba(0, 0, 0, 0.02),
      0 100px 80px rgba(0, 0, 0, 0.04);
  }

  .resp-logo img {
    width: 50px;
  }

  .user-hamburger {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 99;
  }

  .user-hamburger > div {
    background-color: #d79838;
  }

  .user-nav-bar {
    position: fixed;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    background: var(--backgroundTheme);
    flex-direction: column;
    justify-content: flex-start;
    right: -100vw;
    transition-duration: 0.3s;
  }

  .user-nav-bar > div {
    margin: 20px 0;
  }

  .user-nav-bar-ul {
    padding: 0;
  }

  .user-nav-bar-ul ul {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .user-nav-bar-ul ul li {
    display: flex;
    justify-content: center;
  }

  .user-nav-bar-ul a {
    font-size: 19px;
  }

  .user-nav-bar-signup button {
    font-size: 17px;
    margin: 10px 0 0 0;
  }

  .user-nav-bar-signup {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media(max-width: 424px){
  .user-nav-bar-ul{
    margin: 10px 0 0 0 !important;
  }

  .user-nav-bar-logo{
    display: none;
  }

  .user-nav-bar-signup{
    margin: 0 !important;
  }
}
.landing-intro {
  width: 100%;
  min-height: calc(100vh - 100px);
  max-width: 100vw !important;
  overflow: hidden !important;
  /* height: 100vh; this line is useless */
  display: grid;
  grid-template-columns: 50% 50%;
  overflow-x: hidden;
}

.langind-text-container {
  display: block;
  margin: auto 10%;
  /*
  Useless lines
  width: 90%;
  padding-right: 60px;
  */
}

.landing-intro-txt {
  width: 85%;
  height: 100%;
  color: var(--textTheme);
}

.landing-intro .slick-track {
  display: flex;
}
.landing-intro .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.landing-intro-txt-wrapper{
  height: 100%;
  flex-direction: column;
  display: flex;
}
.landing-intro-txt h1 {
  font-size: 4em;
  color: rgb(214, 152, 56);
}

.landing-intro-txt p {
  line-height: 100%;
}

.landing-intro-img-container {
  margin: auto 0;
}

.landing-intro-img {
  height: 70%;
  width: 80%;
  overflow: hidden;
  margin: auto;
  /* Added alignment */
  text-align: center;
}

.landing-intro-img img {
  height: 80%;
  width: 80%;
  border-radius: 500px;
  border: 4px solid white;
  object-fit: contain;
  display: inline-block;
  /* display inline-block important to override slick styles */
  /* Contain istead of cover, made slightly smaller */
}

@media (max-width: 768px) {
  .landing-intro {
    grid-template-columns: 100%;
  }

  .landing-intro-txt-wrapper h1{
    font-size: 32px;
  }
}

.lastwork-section {
  margin: 50px 0;
}

.work-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 100vw;
  box-sizing: border-box;
  align-items: center;
}

.work-info {
  display: flex;
  align-items: center;
  color: var(--textTheme);
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
}

.work-item a {
  text-decoration: none;
}

.work-info .stylist-icon-container {
  height: 30px;
  width: 30px;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 100px;
}

.stylist-icon-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.work-item-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.work-item a {
  -webkit-user-drag: none;
}

.work-item p {
  position: relative;
}

.work-item-img-container {
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  background-color: #fed700;
  padding: 6rem 0;
}

.stat {
  color: #ffffff;
}

.stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1024px) {
  .stats-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .stat{
    margin: 20px 0;
  }
}

@media (max-width: 568px) {
  .stats-container {
    grid-template-columns: auto;
  }
}

.user-services-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  padding: 50px 0;
}

.user-services-wrapper {
  margin: 40px 0;
}

.user-service {
  max-width: 250px;
  overflow: hidden;
  min-height: 250px;
  color: #ffffff;
  position: relative;
}

.popular-stylist-header-new{
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  align-items: center;
  padding: 0 2rem;
}

.user-service .service-image {
  height: 250px;
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  transition-duration: 0.3s;
}


.user-service-info p {
  margin: 5px 0;
}

.service-fake-bg {
  transition-duration: 0.3s;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
}

.service-fake-bg {
  background-color: rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  .user-services-container {
    grid-template-columns: auto auto;
  }

  .user-service {
    margin: 20px 0;
  }
}

@media (max-width: 768px) {
  .user-services-container {
    grid-template-columns: auto;
  }
}

.whyus-container {
  background-color: gold;
  color: rgb(46, 46, 46);
  padding: 2rem 0 4rem 0;
}

.whyus-container h2 {
  text-align: center;
  padding: 2rem 0;
}

.whyus-flex > div {
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
}

.whyus-flex {
  display: flex;
}

.whyus-flex img {
  width: 80px;
}

.popular-stylist-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--textTheme);
}

@media (max-width: 768px) {
  .whyus-flex {
    flex-direction: column;
  }

  .whyus-flex > div{
    align-items: center;
  }

  .popular-stylist-header-new p{
    display: none;
  }
}

.pricing-plans-section{
    background-color: #fed700;
    padding: 3% 3%;
}

.pricing-plans{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.pricing-plan{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    margin: 0 13%;
    border-radius: 25px;
    padding: 7% 4%;
    text-align: center;
}

.pricing-plans-header{
    text-align: center;
    margin-bottom: 4%;
}

.pricing-plans-header h1{
    margin: 0;
    font-size: 1.9em;
}

.plan-title{
    font-weight: bold;
    font-size: 1.2em;
}

.plan-sess{
    font-weight: bold;
}

.plan-header > *{
    margin: .7em 0;
}

.plan-logo{
    width: 15%;
    margin-bottom: 5%;
}

.plan-logo img{
    width:100%;
    height: 100%;
    object-fit: cover;
}

.plan-bottom-part button{
    background-color: tomato;
    border: none;
    padding: 15px 30px;
    border-radius: 25px;
    font-size: 1em;
    color: white;
    cursor: pointer;
}

.plan-bottom-part p{
    font-size: 1.9em;
    color: tomato;
    margin-top: 0;
    margin-bottom: .7em;
}

.plan-desc{
    margin: 17% 0;
    padding: 0 3px;
}

@media only screen and (max-width: 768px){
    .pricing-plans{
        grid-template-columns: initial;
        grid-template-rows: repeat(3,1fr);
    }
    .pricing-plan{
        margin: 6% 10%;
    }
    .pricing-plans-section {
        padding: 7% 3%;
    }
}
.widgets-container{
    background-color: #fed700;
    padding: 1em 0;
}

.widget-item{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 100vw;
    box-sizing: border-box;
    align-items: center;
}

.widget-item-img-container{ 
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
}

.widget-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.widget-item span{
    color: #fff;
    font-size: 1.5em;
}
.banner{
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact {
  width: 100vw;
  height: 100vh;
  color: var(--textTheme);
}

.contact-header-wrapper {
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #ecc903;
  justify-content: center;
  color: #ffffff;
}

.contact-header {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.contact-part {
  text-align: center;
  position: relative;
  padding: 15px 0;
}

.contact-part img {
  width: 60px;
  position: absolute;
  bottom: 0;
}

.contact-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 50px;
}

.contact-info-img-container {
  margin-bottom: 35px;
}

.contact-info-img-container img {
  width: 80%;
  height: auto;
  object-fit: cover;
}

.contact-icon {
  width: 1.4em;
  height: auto;
  margin-right: 10px;
  vertical-align: middle;
}

.contact-info-details {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr 1fr;
}

.contact-icon img {
  width: 100%;
  height: 100%;
  min-width: 20px;
  object-fit: cover;
  -webkit-filter: invert(1);
          filter: invert(1);
}

.contact-address {
  grid-column: 1 / span 2;
}

.contact-info-item {
  display: flex;
  align-items: center;
}

.contact-info-item p {
  font-size: 1.1em;
  vertical-align: middle;
}

/* .contact-form-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
 */

.contact-input-container p {
  display: inline-block;
  margin-bottom: 5px;
}
.contact-input {
  width: 100%;
  font-size: 1.1em;
  padding: 13px 5px 13px 13px;
  margin: auto;
  margin-bottom: 15px;
  border: none;
  border-bottom: 1px solid rgb(207, 210, 219);
  background: var(--backgroundTheme);
  color: var(--textTheme);
  outline: none;
  border-radius: 0;
  transition-duration: 0.3s;
}
/*
form{
    padding: 0 20%;
}
*/

.contact-input:focus {
  border-color: goldenrod;
}

.contact-form-btn {
  width: 100%;
  padding: 10px 0;
  text-align: center;
}

.contact-form-btn button {
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: rgb(255, 148, 92);
  border: 1px solid transparent;
  font-size: 1.1em;
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: 0.4s;
}

.contact-form-btn button:hover {
  color: rgb(255, 148, 92);
  background-color: transparent;
  border: 1px solid rgb(255, 148, 92);
}

.contact-form-header {
  text-align: center;
}

.contact-info-detail {
  padding-left: 12%;
  padding-bottom: 7%;
}

.contact-info-item-name h4 {
  display: inline;
}

@media (max-width: 768px) {
  .contact-wrapper {
    grid-template-columns: 100%;
    padding: 15px;
  }

  .contact-part img {
    display: none;
  }

  .contact-info-img-container {
    text-align: center;
  }

  .contact-info-detail:nth-last-child(){
    width: 100%;
  }
}

.auth-page {
  background-color: var(--backgroundTheme);
  height: 100%;
  width: 100%;
}

.auth-form-- {
  display: flex;
  padding-top: 3rem;
  flex-direction: column;
  justify-content: center;
  color: var(--textTheme);
  max-width: 100vw;
}

.top-left-logo{
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
}

.top-left-logo img{
  width: 70px;
}

.auth-form {
  display: flex;
  background: var(--backgroundTheme);
  padding: 5rem;
  z-index: 0;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  width: 100%;
}

.auth-flat {
  width: 30vw;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

.auth-flat .flat-img {
  height: 80%;
  width: 80%;
  margin: 20px;
}

.auth-logo{
  width: 6rem !important;
}

.agreement {
  margin-top: 1.3rem;
}

.auth-btn-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.auth-btn-container span {
  font-size: 0.8em;
  margin-top: 0.3em;
}

.auth-btn-container span a {
  text-decoration: none;
  color: #fff;
}

input[type="chechbox"] {
  cursor: pointer;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .auth-form {
    flex-direction: column;
    padding: 60px 40px 40px 40px;
    box-sizing: border-box;
    min-height: 100%;
  }

  .auth-form--{
    margin-top: 12rem;
    position: relative;
  }

  .auth-flat {
    max-height: 40vh;
    margin: 40px 0;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .auth-flat img {
    max-width: 55vw;
  }
}


@media(max-width: 424px){
  .auth-form--{
    max-width: 80vw;
    margin-top: 5rem;
  }
}
.notfound-icon {
  text-align: center;
  max-width: 100vw;
  color: var(--textTheme);
}

.notfound-button-container {
  text-align: center;
  margin-top: 50px;
}

.notfound-icon img{
  max-width: 100vw;}

.notfound-icon h2 {
  margin: 0;
}

@media(max-width: 768px){
  .notfound-icon{
    margin-top: 20vh;
  }
}
.stylist-info-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  background-color: #f7f7f7;
  height: calc(100vh - 72px - 2rem);
  position: relative;
  overflow: hidden;
}

.stylist-img {
  position: relative;
  height: 100%;
  max-height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.active-time {
  background-color: var(--gold);
  color: #ffffff !important;
  border-color: #ffffff;
}

.active-time:hover {
  color: #ffffff !important;
  border-color: #ffffff !important;
}

.stylist-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  background: rgba(0, 4, 24, 0.897);
}

.stylist-info .stylist-name {
  font-size: 45px;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
}

.gallery-item {
  height: 250px;
  width: 250px;
  overflow: hidden;
  margin: 40px 0;
}

.stylist-bio {
  color: var(--textTheme);
  display: flex;
  flex-direction: column;
}

.stylist-bio .golden-button {
  max-width: 10em;
}

.stylist-bio h3 {
  margin: 5px 0;
}

.stylist-status {
  margin-top: 15px;
}

.gallery-item img {
  width: 100%;
  transition-duration: 0.3s;
  height: 100%;
  object-fit: cover;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.transparent {
  background-color: transparent !important;
  max-height: 80vh !important;
  max-width: 100vw !important;
  display: flex;
  padding: 0 !important;
  margin: 30px !important;
  justify-content: center;
}

.transparent img {
  max-width: 80% !important;
  max-height: 70vh !important;
}

.transparent .user-modal-x {
  display: none;
}

.book-modal {
  max-height: 90vh !important;
  max-width: 60vw !important;
  color: var(--textTheme);
}
.book-hours {
  max-height: 40vh;
  overflow-y: scroll;
  padding-right: 5px;
}

.booked-hour {
  background: red;
  pointer-events: none;
  cursor: no-drop !important;
}

.book-container {
  display: flex;
}

.book-container .rdrCalendarWrapper {
  background-color: var(--backgroundTheme);
}

.book-container span {
  color: var(--textTheme);
}

.book-container .rdrDayDisabled {
  background-color: rgb(5, 0, 20);
}

.book-hours {
  margin-top: 30px;
}

.book-service {
  color: var(--textTheme);
}

.book-service select {
  padding: 8px 12px;
  background: var(--backgroundTheme);
  color: var(--textTheme);
  margin-left: 10px;
}

.book-hour {
  border: 1px solid var(--textTheme);
  margin: 2px 0;
  transition-duration: 0.3s;
  cursor: pointer;
  color: var(--textTheme);
  padding: 8px;
}

.book-hour:hover {
  border-color: var(--gold);
  color: var(--gold);
}

@media (max-width: 1024px) {
  .gallery-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .book-hours {
    display: flex;
    max-width: 90vw;
    overflow-y: hidden;
  }
  .stylist-info-grid {
    grid-template-columns: 100%;
    height: auto;
  }

  .stylist-info {
    padding-bottom: 50px;
  }

  .stylist-img {
    height: 70vh;
  }

  .book-container {
    flex-direction: column;
    align-items: center;
  }

  .book-modal {
    max-width: 100vw !important;
    max-height: 100vh !important;
    height: 100vh !important;
    border-radius: 0 !important;
    width: 100vw !important;
    padding-top: 60px !important;
    overflow: auto;
  }

}

@media (max-width: 550px) {
  .gallery-container {
    grid-template-columns: auto;
  }
}

.stylists-grid {
  padding: 20px;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  display: grid;
  min-height: 10em;
  grid-template-columns: repeat(4, 1fr);
  background-color: var(--backgroundTheme);
}

.user-bookings-header {
  display: flex;
  flex-direction: column;
  transition-duration: .3s;
}

.stylist-flex{
  display: flex;
  justify-content: space-around;
}

.red{
  color: red;
}

.green{
  color: green;
}

.user-stylist-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  color: var(--textTheme);
  background: rgba(0, 0, 0, 0.315);
}

.user-stylist-name {
  font-size: 23px;
}

.user-stylist-info {
  padding: 10px 0;
  text-align: center;
}

.user-stylist-info .border {
  height: 1px !important;
  margin: 0 !important;
  transition-duration: 0.3s;
}

.golden {
  background: rgb(255, 217, 0) !important;
}

.user-stylist-info p {
  margin: 5px 0;
}

.user-stylist-image-container {
  height: 300px;
  overflow: hidden;
  position: relative;
}

.user-stylist-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition-duration: 0.3s;
}

.user-stylist-image-container:hover img {
  transform: scale(1.1);
}

.user-stylist-container:hover .border {
  width: 150px !important;
}

.stylists-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/stylists.6bde725e.jpg) !important;
  background-position: center 30% !important;
}
.makeup-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/makeup.74ab8b7f.jpg) !important;
  background-position: center 60% !important;
}
.cosmetolog-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/cosmetolog.bfaf2669.jpg) !important;
  background-position: center 50% !important;
}
.nails-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/nails.585eeee4.jpg) !important;
  background-position: center 60% !important;
}

.centered-bg {
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.user-switch-container {
  display: flex;
  margin-top: 15px;
}

.user-switch-container div {
  border: 1px solid #ffffff;
  color: #ffffff;
  padding: 8px 12px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.user-switch-active {
  background-color: #d79839;
}

@media (max-width: 768px) {
  .prof-switch {
    flex-direction: column;
  }

  .user-bookings-header{
    height: auto !important;
    padding: 20px 0;
  }
}

.user-bookings {
  background-color: var(--backgroundTheme);
  min-height: 100vh;
}

.user-bookings-header {
  height: 30vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/static/media/salon.6d4653b5.jpg);
  background-size: 100%;
  background-position: center 40%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-bookings-header h2 {
  margin: 0;
  color: #ffffff;
}

.bookings-info{
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-filter: invert(1);
          filter: invert(1);
  min-height: 10rem;
}

.bookings-info h1{
  display: inline-block;
  margin-left: 2rem;
}

.blue-border {
  border-color: rgb(87, 87, 204) !important;
}

.blue {
  color: rgb(87, 87, 204) !important;
}

.user-book {
  position: relative;
}

.user-book h4 {
  margin: 10px 0;
}

.date-span {
  margin: 10px 0;
  color: var(--gold);
}

.delete-book {
  position: absolute;
  bottom: 20px;
  right: 20px;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition-duration: 0.3s;
}

.user-book:hover .delete-book {
  opacity: 1;
  pointer-events: all;
}

.user-book {
  display: flex;
  flex-direction: column;
  background-color: #000014;
  color: var(--textTheme);
  margin: 20px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4.1px 10px rgba(0, 0, 0, 0.04), 0 33px 80px rgba(0, 0, 0, 0.08);
}

.user-book-info {
  display: flex;
  flex-direction: column;
}

.user-book-info {
  border-left: 2px solid var(--gold);
  padding-left: 20px;
}

.profile-header {
  position: relative;
  min-height: 30vh;
  background: linear-gradient(to right, rgb(255, 221, 26), rgb(255, 217, 0));
}

.profile-header > h1 {
  position: absolute;
  bottom: 20px;
  color: #ffffff;
  padding-left: 20px;
  margin: 0;
}

.profile-item {
  display: flex;
  color: #ffffff;
  margin: 20px;
  padding: 20px 30px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  transition-duration: .3s;
}

.profile-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 50px;
}

.profile-item:hover{
  transform: translateY(-5px);
}

.profile-info h2 {
  margin: 5px 0;
}

.profile-info h4 {
  margin: 5px 0;
}

@media(max-width: 424px){
  .profile-item{
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
  }
}
.profile-pic-wrapper {
  height: 200px;
  width: 200px;
  border: 2px solid gray;
  margin: 0 20px;
  transform: translateY(10%);
  position: relative;
  border-radius: 100px;
  overflow: hidden;
}

.profile-pic-wrapper:hover .upload-pic {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.upload-pic {
  position: absolute;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  color: #ffffff;
  opacity: 0;
  transition-duration: 0.3s;
  pointer-events: none;
}

.upload-pic span {
  margin-right: 5px;
}

.profile-pic-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.profile-pic {
  display: flex;
  align-items: flex-end;
}

.profile-pic h1 {
  color: #ffffff;
}

.pics-grid {
  justify-items: center;
  padding-top: 20px;
  background: var(--backgroundTheme);
}

.gallery-item-wrapper {
  position: relative;
}

.gallery-item-wrapper .delete-book {
  bottom: 60px;
  right: 20px;
}

.stylist-nav {
  display: flex;
  background: var(--backgroundTheme);
  padding-top: 20px;
  justify-content: space-around;
  color: var(--textTheme);
}

.stylist-nav div{
  padding-bottom: 5px;
  cursor: pointer;
}

.stylist-nav-active {
  color: gold;
  border-bottom: 1px solid gold;
}

@media (max-width: 768px) {
  .gallery-item-wrapper .delete-book {
    bottom: 30px;
    right: 30px;
  }
}

.gallery-item-wrapper:hover .delete-book {
  opacity: 1;
  pointer-events: all;
}

.upload-photo {
  height: 250px;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.3);
  margin: 40px;
  display: flex;
  color: var(--textTheme);
  justify-items: center;
  align-items: center;
  cursor: pointer;
}

.upload-photo-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.gallery-item-content {
  display: flex;
  justify-content: center;
}

.gallery-item-content button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.salary-header{
  color: var(--textTheme);
  text-align: center;
  margin: 25px 0 0 0;
}

.stylist-salary-container .total{
  color: var(--textTheme);
}



.stylist-salary-container .cv-vipped-item{
  background-color: var(--gold) !important;
}

.stylist-salary-container{
  min-height: 40vh;
  padding-bottom: 20px;
}

.salary-item-container{
  display: flex;
  color: #ffffff;
  background: rgb(0, 0, 24);
  margin: 10px;
  padding: 10px;
  border-radius: 4px;
}

.profile-header{
  position: relative;
}

.margined-span{
  margin-right: 20px;
}

.profile-name{
  display: flex;
}

.toggle-wrapper{
  display: flex;
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.toggle-wrapper span{
  color: var(--textTheme);
  padding-right: 10px;
}

@media (max-width: 768px) {
  .profile-pic-wrapper {
    transform: none;
    margin: 10px;
  }

  .pics-grid .upload-photo {
    margin: 20px;
  }

  .pics-grid .gallery-item {
    margin: 20px;
  }

  .cv-vip-container{
    flex-direction: column;
  }

  .profile-pic{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
  }
}

@media(max-width: 500px){
  .toggle-wrapper{
    bottom: unset;
    top: 15px;
  }
}
.user-services-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  margin: 50px 30px;
}

.user-service-item {
  color: var(--textTheme);
  display: inline-flex;
  width: 80%;
  justify-content: space-between;
  border-bottom: 1px solid rgb(255, 221, 30);
  transition-duration: .3s;
}

.user-service-item p {
  font-size: 19px;
}

.services-search {
  text-align: center;
}

.services-search input{
  border: none;
  border-bottom: 1px solid gold;
  background: transparent;
  font-size: 19px;
  padding: 8px 12px;
  color: var(--textTheme);
  margin-top: 20px;
}

@media (max-width: 768px) {
  .user-services-grid {
    grid-template-columns: auto;
  }

  .user-service-item {
    width: 90%;
  }
}

.books-page {
  background-color: #f3f3f3;
}

.manager-bookings-list {
  width: 80vw;
  margin-left: 20vw;
}

.manager-book-container th {
  font-weight: 1;
}

.red-button svg, .red-button span{
  -webkit-filter: invert(27%) sepia(74%) saturate(6333%) hue-rotate(352deg) brightness(92%) contrast(129%);
          filter: invert(27%) sepia(74%) saturate(6333%) hue-rotate(352deg) brightness(92%) contrast(129%);
}

.manager-book-container th button {
  margin-left: 10px;
}

.manager-book-container span {
  margin: auto;
}

.big-font {
  font-size: 16px;
  padding-left: 8px 12px;
  margin-top: 15px;
}

.th-flex {
  display: flex;
}

.new-time-container {
  display: flex;
  flex-direction: column;
}

.new-time-container > div{
  display: flex;
}

@media (max-width: 768px) {
  .manager-bookings-list {
    width: 100vw;
    margin: 0;
  }
}

.forgot-pass-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
}

.forgot-pass-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  background: rgb(0, 11, 31);
  padding: 30px;
  border-radius: 12px;
}

.forgot-pass-form input {
  background: rgb(0, 11, 31) !important;
}

.forgot-pass-form .golden-button {
  margin-top: 20px;
}

@media(max-width: 768px){
  .forgot-pass-form{
    max-width: 90vw;
  }
}
.message-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-form h3 {
  color: #ffffff;
}

.message-form textarea {
  font-size: 19px;
  padding: 10px;
  min-width: 400px;
  min-height: 10rem;
}

.message-form input{
  min-width: 400px;
  padding: 10px;
  font-size: 19px;
}

.message-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  background-color: var(--panelBg);
  border-radius: 20px;
}

.message-form > *{
    margin: 5px 0;
}
.widget-item{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 100vw;
    box-sizing: border-box;
    align-items: center;
}

.widget-item-img-container{ 
  height: 350px;
  overflow: hidden;
  border-radius: 10px;
}

.widget-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.widget-item span{
    color: #fff;
    font-size: 1.5em;
}

.items-container-widget{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.halp-item {
  display: flex;
  justify-content: space-around;
  margin: 1em 0;
}

.halp-item img {
  width: 50%;
  margin-right: 1em;
}

.halp-page {
  color: #fff;
  padding: 2em;
}

.halp-page h1 {
  text-align: center;
}

#bonus-points {
  display: flex;
  flex-direction: column;
}

.sale-item {
  display: flex;
  background-color: #0c0920;
  padding: 1em;
  margin: 1em;
}

.sale-item span {
  margin: 0 10px;
  color: #fff;
}

.greeting-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  background-image: url(/static/media/gold.e28ed5be.jpg);
  background-size: cover;
  background-position: center;
  transition-duration: 0.3s;
}

.greeting-container h1 {
  color: #ffffff;
  text-align: center;
}

.fade-out {
  opacity: 0;
  pointer-events: none;
}

.fade-in {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

body {
  margin: 0;
  font-family: "BPG Nino Medium", sans-serif;
  overflow-x: hidden !important;
}

:root {
  --backgroundTheme: #1c252e;
  --textTheme: #fff;
  --panelBg: #0f0c29;
  --panelDim: #252849;
}

body {
  background: #1c252e;
  background: var(--backgroundTheme);
}

.page {
  min-height: 100vh;
  position: absolute;
  width: 100vw;
  background-color: #252849;
  background-color: var(--panelDim);
}

html {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-x: hidden;
}

.dark {
  background-color: #1c252e !important;
  background-color: var(--backgroundTheme) !important;
}

.grid-page {
  display: grid;
  grid-template-columns: 20% 80%;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
  -webkit-transition: opacity 300ms linear 150ms;
  -moz-transition: opacity 300ms linear 150ms;
  -ms-transition: opacity 300ms linear 150ms;
  -o-transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
  -webkit-transition: opacity 150ms linear;
  -moz-transition: opacity 150ms linear;
  -ms-transition: opacity 150ms linear;
  -o-transition: opacity 150ms linear;
}

.outside-wrapper {
  transition-duration: 0.4s;
  background: #000014;
  z-index: 99;
  -webkit-box-shadow: 0px 0px 0 1000vw rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 0 1000vw rgba(0, 0, 0, 0.75);
  max-height: 90vh;
  overflow: auto;
  box-shadow: 0px 0px 0 1000vw rgba(0, 0, 0, 0.75);
  position: fixed;
  border-radius: 12px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.primary-button {
  transition-duration: 0.3s !important;
}

.primary-button:active {
  transform: scale(0.9);
}

.primary-button[disabled] {
  transform: none;
}

* {
  outline-style: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .grid-page {
    display: grid;
    grid-template-columns: auto;
  }
}

@-webkit-keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

:root {
  --gold: #d79839;
}

.reusable-input {
  display: inline-flex;
  padding: 4px 4px 8px 4px;
  border-bottom: 1px solid rgb(165, 161, 161);
  margin: 10px 0;
  transition-duration: 0.3s;
  width: 20rem;
  min-height: 30px;
  max-width: 100%;
}

.reusable-input input {
  border: none;
  padding-left: 10px;
  font-size: 15px;
  height: 20px;
  background: var(--backgroundTheme);
  color: var(--textTheme);
  width: 100%;
}

.golden-button {
  background-color: #d79839;
  background-color: var(--gold);
  color: #ffffff;
  border: none;
  border-radius: 200px;
  font-size: 19px;
  padding: 0.7rem 2rem;
  cursor: pointer;
}

.invalid-input {
  border-color: rgb(255, 35, 35);
}

.invalid-input input {
  color: rgb(255, 35, 35);
}

.invalid-input.input-focused {
  border-color: rgb(255, 35, 35) !important;
}

.input-focused {
  border-color: gold;
}

.input-focused input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1; /* Firefox */
}

.input-focused input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1; /* Firefox */
}

.input-focused input::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1; /* Firefox */
}

.input-focused input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 1; /* Firefox */
}

.link {
  color: gold;
  text-decoration: underline;
}

.user-primary-button {
  border: none;
  background-color: #6496ed;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition-duration: 0.3s;
}

.user-load-button {
  color: rgba(0, 0, 0, 0);
  background-image: url(/static/media/loading.b23df65d.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30%;
}

.user-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 99;
  transition-duration: 0.3s;
}

.user-modal {
  padding: 30px;
  background-color: var(--backgroundTheme);
  border-radius: 16px;
  color: var(--colorTheme) !important;
  position: relative;
  max-width: 40vw;
  max-height: 40vh;
  min-width: 30vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  -webkit-animation: spawn 0.3s;
          animation: spawn 0.3s;
}

.user-modal img{
  max-height: 100%;
  max-width: 100%;
}

.user-modal-x {
  position: absolute;
  top: 10px;
  right: 10px;
  -webkit-filter: invert(1);
          filter: invert(1);
  cursor: pointer;
}

.toggle-container{
  height: 20px;
  width: 40px;
  border-radius: 20px;
  background:gray;
  transition-duration: .3s;
  cursor: pointer;
}

.toggle-container div{
  height: 18px;
  width: 18px;
  margin: 1px;
  display: inline-flex;
  border-radius: 20px;
  background: var(--textTheme);
  transition-duration: .3s;
}

.toggled{
  background-color: red;
}

.toggled div{
  transform: translateX(20px);
}

@-webkit-keyframes spawn {
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.01);
  }
  75% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spawn {
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.01);
  }
  75% {
    transform: scale(0.99);
  }
  100% {
    transform: scale(1);
  }
}

.lari img {
  width: 18px;
  transform: translateY(2px);
}

.lari .lari-white {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.black {
  background-color: #000000 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d79839;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8762c;
}

@media (max-width: 768px) {
  .user-modal {
    max-width: 90vw;
    max-height: 90vh;
  }
}
.responsive-grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1024px) {
  .responsive-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 768px) {
  .responsive-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 568px) {
  .responsive-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

