.records-page-content {
  background-color: var(--panelDim);
  padding-bottom: 30px;
  overflow-x: auto !important;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: calc(100% - 40px);
  margin: 20px;
}

td {
  text-align: left;
  padding: 10px 8px;
}

th {
  color: #ffffff;
  padding: 10px 8px;
  text-align: left;
}

.tr-header {
  background-color: #0c0920 !important;
}


tr {
  background-color: var(--panelBg);
  color: #ffffff !important;
  border-bottom: 1px solid rgba(228, 228, 228, 0.685);
}

th{
  color: #ffffff !important;
}

.records-search {
  display: inline-flex;
}

.records-search-container {
  text-align: center;
  margin: 20px 0;
}

.records-search input {
  padding: 10px;
  height: 45px;
  border: 1px solid rgb(182, 182, 182);
  font-size: 17px;
}

.records-search button {
  height: 45px;
  width: 45px;
  background-image: url("./icons/search.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
}
