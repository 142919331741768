.halp-item {
  display: flex;
  justify-content: space-around;
  margin: 1em 0;
}

.halp-item img {
  width: 50%;
  margin-right: 1em;
}

.halp-page {
  color: #fff;
  padding: 2em;
}

.halp-page h1 {
  text-align: center;
}

#bonus-points {
  display: flex;
  flex-direction: column;
}
