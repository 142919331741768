.forgot-pass-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20vh;
}

.forgot-pass-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  background: rgb(0, 11, 31);
  padding: 30px;
  border-radius: 12px;
}

.forgot-pass-form input {
  background: rgb(0, 11, 31) !important;
}

.forgot-pass-form .golden-button {
  margin-top: 20px;
}

@media(max-width: 768px){
  .forgot-pass-form{
    max-width: 90vw;
  }
}